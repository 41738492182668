import React from "react";
import { Layout } from "../../components/Layout";
import { HeaderBuffer } from "../../components/Layout/Header";
import outdoorService from "../../images/outdoor-service-1.jpg";

const WhatToExpectPage = () => {
  return (
    <Layout>
      <main className="min-h-[80vh]">
        <div className="bg-cyan-950">
          <HeaderBuffer />
        </div>
        <div className="w-full px-8 py-16 md:px-16 md:py-16 bg-cyan-900">
          <h1 className="text-white text-5xl font-serif text-center md:text-left">What to Expect</h1>
        </div>
        <div className="p-8 md:p-16 grid grid-flow-row grid-cols-1 md:grid-cols-2 items-center gap-8 md:gap-16">
          <div>
            <h3 className=" text-cyan-900 text-2xl font-light">
              Welcome to Wellspring Bible Church!
            </h3>
            <div className="text-md text-gray-500">
              We are real people seeking to follow Christ and walk with him
              daily. Our church is a welcoming community that seeks to grow
              spiritually and learn what it means to follow Jesus together.
              Wherever you are in your experience with Christianity, you will
              fit in with us.
            </div>
          </div>
          <div>
            <img
              src={outdoorService}
              className="object-contain rounded-2xl shadow-xl"
            />
          </div>
        </div>
        <div className="p-8 md:p-16 bg-slate-200 flex flex-col">
          <h3 className="text-cyan-900 text-2xl mb-8 font-light">
            Got Questions?
          </h3>

          <div className="md:w-2/3 lg:w-1/2 sm:w-full">
            <div className="text-lg text-cyan-900 font-bold">
              Where do I go once I arrive?
            </div>
            <div className="text-md text-gray-500">
              The main entrance is facing the parking lot and has a sign on it.
              Once you enter, you'll be in the foyer and be greeted by our
              Welcome Team! The sanctuary entrance will be just ahead on the
              right. Feel free to grab a coffee and snack in the foyer.
            </div>
          </div>
          <div className="mt-6 md:w-2/3 lg:w-1/2 sm:w-full">
            <div className="text-lg text-cyan-900 font-bold">
              What happens during the worship service?
            </div>
            <div className="text-md text-gray-500">
              Our services contain various worship elements including worship in
              song, prayer, Scripture reading, and a Bible-based sermon.
            </div>
          </div>
          <div className="mt-6 md:w-2/3 lg:w-1/2 sm:w-full">
            <div className="text-lg text-cyan-900 font-bold">
              Do you have anything for my kids?
            </div>
            <div className="text-md text-gray-500">
              We offer children's church during the sermon for kids K-6th grade.
              The children are in the santuary until the sermon. We also offer
              Sunday school for kids during ABF (Adult Bible Fellowship) prior
              to service. We do not currently have a nursery but we are working
              on it!
            </div>
          </div>
          <div className="mt-6 md:w-2/3 lg:w-1/2 sm:w-full">
            <div className="text-lg text-cyan-900 font-bold">
              What should I wear?
            </div>
            <div className="text-md text-gray-500">
              Some of us wear jeans and t-shirts, some wear dresses or suits.
              There is no dress code. Come as you are!
            </div>
          </div>
          <div className="mt-6 md:w-2/3 lg:w-1/2 sm:w-full">
            <div className="text-lg text-cyan-900 font-bold">
              What do you teach in your sermons?
            </div>
            <div className="text-md text-gray-500">
              Our sermons are rooted in the Bible and designed to help you
              understand how to apply the Bible to your life. We typically
              preach through books of the Bible (e.g. a series on Matthew) and
              occasionally preach on specific topics. We believe that the Bible
              is the inspired Word of God and is the final authority in all
              matters of faith and practice.
            </div>
          </div>
          <div className="mt-6 md:w-2/3 lg:w-1/2 sm:w-full">
            <div className="text-lg text-cyan-900 font-bold">
              What kind of worship music do you sing?
            </div>
            <div className="text-md text-gray-500">
              We sing a blend of modern worship songs and traditional hymns.
            </div>
          </div>
          <div className="mt-6 md:w-2/3 lg:w-1/2 sm:w-full">
            <div className="text-lg text-cyan-900 font-bold">
              I have more questions. Who can I talk to?
            </div>
            <div className="text-md text-gray-500">
              We'd love to talk! Feel free to give us a call at{" "}
              <a
                className="text-blue-500 hover:underline"
                href="tel:330-596-1398"
              >
                330-596-1398
              </a>{" "}
              or send us a message via{" "}
              <a
                className="text-blue-500 hover:underline"
                href="https://www.facebook.com/WellSpringBibleChurch"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
              .
            </div>
          </div>
        </div>
        <div
          className="flex flex-col items-center self-center px-6 pt-16 pb-16 bg-cyan-900"
          id="worship"
        >
          <h2 className="text-white text-4xl text-center mb-8 font-serif">
            Come Worship With Us!
          </h2>
          <div className="text-white text-lg text-center">
            <span className="font-bold">Worship service:</span> <span className="font-light">10:30am every Sunday</span>
          </div>
          <div className="text-white text-lg text-center">
            <span className="font-bold">ABF (Bible Study):</span> <span className="font-light">9:30am every Sunday</span>
          </div>
          <div className="text-white text-lg text-center">
            <a
              className="text-blue-400 visited:text-purple-400"
              href="https://maps.app.goo.gl/VskRN7sz92ycgCZg7"
              target="_blank"
              rel="noreferrer"
            >
              304 North Rockhill Ave, Alliance, OH 44601
            </a>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3014.596535192252!2d-81.11826072437096!3d40.9245915245743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8836b4d9150a75e5%3A0x5137d3748da75b6d!2s304%20N%20Rockhill%20Ave%2C%20Alliance%2C%20OH%2044601!5e0!3m2!1sen!2sus!4v1696386169891!5m2!1sen!2sus"
            width="300"
            height="300"
            style={{ border: 0, marginTop: "1rem" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </main>
    </Layout>
  );
};

export default WhatToExpectPage;
